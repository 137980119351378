

import React from 'react'
import SeoPage from '../components/SeoPage'

import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/contacter-engager-avocats-granby-soumissions-litige-768x512.jpg'



const Granby = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Entrez en contact avec les meilleurs avocats à Granby pour résoudre vos différends légaux - Soumissions Avocat"
        description="Les avocats les plus compétents de Granby se trouvent ici et l'un d'eux défendra votre cause devant les tribunaux ou négociera le meilleur arrangement possible pour vous ou votre compagnie. En droit pénal, administratif, du travail ou autres : le meilleur avocat de Granby sera là pour vous représenter."
        image={LeadImage}>
        <h1>Entrez en contact avec les meilleurs avocats à Granby pour résoudre vos différends légaux</h1>
        <p>
            Les avocats sont des professionnels parfois mal compris. Comme leurs
            services sont requis dans des situations de nécessité peu agréables, on
            identifie souvent leur intervention à une expérience négative. Qu’il
            s’agisse d’une poursuite civile ou d’un procès criminel, il y a fort à
            parier que vous n’envisagez pas votre aventure dans l’appareil judiciaire
            comme étant enrichissante ou même plaisante. C’est tout à fait comprenable.
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/contacter-engager-avocats-granby-soumissions-litige-768x512.jpg"
                alt="contacter-engager-avocats-granby-soumissions-litige"
            />
        </p>
        <p>
            D’ailleurs, les avocats sont là pour alléger ce désagrément qu’est la
            procédure judiciaire et ainsi vous offrir la tranquillité d’esprit que vous
            méritez. En plus de cette paix intérieure, c’est gain de cause que vous
            obtiendrez en faisant avec un avocat compétent. Nous vous présentons donc
            les services des juristes les plus qualifiés dans la région de Granby pour
            un règlement rapide de votre problème.
        </p>
        <h2>
            Dans quels domaines les avocats de Granby pratiquent-ils?
        </h2>
        <p>
            La pratique du droit consiste à rattacher une situation factuelle aux
            dispositions législatives pertinentes. C’est le rôle premier de l’avocat
            qui, en se fondant sur ce principe général, tentera de convaincre le juge
            que le droit devrait s’appliquer en votre faveur. Cette tâche demeure la
            même peu importe le domaine de droit pour lequel vous recherchez une
            assistance légale, mais encore faut-il rattacher votre problème à l’un
            d’entre eux avant d’analyser la loi. Voyez ci-dessous les secteurs les plus
            achalandés de la sphère juridique québécoise.
        </p>
        <p>
            <strong>Droit administratif : </strong>
            Cette brache du droit est principalement utile pour les citoyens se
            retrouvant dans une situation de litige contre l’administration publique du
            Québec, quelle que soit la nature plus précise de celle-ci. Que vous ayez
            été victime d’une faute de cette même administration engendrant des
            dommages ou que vous souhaitiez faire valoir vos droits enfreints, un
            avocat en droit administratif est l’expert tout indiqué.
        </p>
        <p>
            <strong>Droit des successions : </strong>
            Loin de se limiter à la rédaction testamentaire, le droit des successions
            fait partie de la compétence des avocats à plusieurs égards. De prime
            abord, la planification d’une succession comprend des éléments légaux et
            financiers intrinsèquement liés. L’élaboration d’un plan de succession
            exige donc l’intervention d’un avocat pour assurer que l’héritage soit
            transmis dans son intégralité et avec un paiement d’impôts post-mortem
            minimal. C’est d’ailleurs la raison pour laquelle les avocats et les
            planificateurs financiers s’allient pour de telles planifications.
        </p>
        <p>
            <strong>Droit criminel et pénal : </strong>
            Le domaine du droit criminel en est un si complexe que les avocats y
            œuvrant suivent souvent des formations complémentaires à leur parcours
            obligatoire pour parfaire leurs aptitudes. Comme les enjeux sont
            extrêmement élevés, il ne faut pas négliger d’engager un avocat qui se
            spécialise dans le droit criminel. Une simple formalité subtile pourrait
            vous innocenter des charges qui pèsent contre vous.
        </p>
        <p>
            <strong>Droit de la jeunesse :</strong>
            Les enfants ainsi que les adolescents d’âge mineur ont des droits que la    <em>Charte des droits et libertés</em> reconnaît expressément. Comme
            ceux-ci ont des intérêts particuliers à préserver et qu’ils sont
            fréquemment entraînés contre leur gré dans des disputes ne les concernant
            pas directement, ils ont besoin d’une représentation légale particulière.
        </p>
        <p>
            De ce fait, les avocats en droit de la jeunesse travaillent en étroite
            collaboration avec le Directeur de la protection de la jeunesse (DPJ) afin
            de protéger les enfants se trouvant dans des situations familiales
            problématiques. Ceux-ci sont également habilités à représenter les enfants
            en cour lorsqu’il est dans leur meilleur intérêt qu’ils soient représentés.
        </p>
        <p>
            <strong>Droit du travail :</strong>
            Syndiqué ou non, vous avec le droit d’être représenté par un avocat pour
            faire valoir les droits enfreints par un employeur. Bien que la
            syndicalisation vous force à passer par les étapes prévues dans la
            convention pour entreprendre certains types de recours, la porte demeure
            toujours ouverte pour les disputes qui dépassent les compétences de votre
            syndicat et pour les travailleurs non-syndiqués. Le droit du travail est
            une spécialisation, alors engagez un avocat qui y consacre le plus clair de
            sa pratique!
        </p>
        <p>
            <strong>Droit des assurances :</strong>
            L’avocat en droit des assurances est l’expert que vous deviriez contacter
            lorsque votre assureur refuse de vous octroyer l’indemnité que à laquelle
            vous vous croyez redevable. Celui-ci représentera vos intérêts vis-à-vis
            votre assureur et tentera de présenter une interprétation du contrat qui
            vous est favorable.
        </p>
        <p>
            <strong> </strong>
        </p>
        <h2>
            Quel est le prix d’un avocat et de quelle façon payez-vous pour cette
            expertise légale?
        </h2>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/prix-honoraires-avocat-expertise-legale-granby.jpg"
                alt="prix-honoraires-avocat-expertise-legale-granby"
            />
        </p>
        <p>
            Une question présente sur toutes les lèvres lorsque vient temps de parler
            d’engager un avocat : à combien s’élèvera le prix final! Pour offrir une
            réponse précise, il vous faut avant tout savoir que plusieurs critères
            propres à votre situation feront varier le prix, dont votre admissibilité à
            l’aide juridique gouvernementale. Au niveau des facteurs influençant le
            coût des services de votre avocat, on peut compter :
        </p>
        <ul>
            <li>
                <em>La durée du dossier,</em>
            </li>
            <li>
                <em>La complexité des démarches,</em>
            </li>
            <li>
                <em>Le nombre de procédures à appliquer,</em>
            </li>
            <li>
                <em>L’expérience de l’avocat,</em>
            </li>
            <li>
                <em>La réputation de l’avocat,</em>
            </li>
            <li>
                <em>Le cabinet d’affiliation.</em>
            </li>
        </ul>
        <p>
            Outre ces critères le type d’honoraires préconisés par votre avocat auront
            également une certaine influence. En effet, la majorité d’entre eux
            fonctionnent selon un taux horaire variant entre    <strong>150$ et 350$ de l’heure</strong>. Vous devinerez que plus un
            dossier démontre une complexité importante, plus le nombre d’heures passées
            à le régler seront élevées, ce qui aura le même effet sur votre facture.
        </p>
        <p>
            Ne soyez cependant pas surpris d’apprendre pendant vos démarches de
            recherche que certains avocats optent pour des rémunérations alternatives.
            C’est notamment les cas des avocats qui préfèrent charger un    <strong>montant forfaitaire</strong>, un <strong>taux conditionnel</strong>
            , un <strong>pourcentage</strong> sur le montant octroyé ou encore un    <strong>accord de paiement</strong> périodique entre celui-ci et son le
            client.
        </p>
        <p>
            Tel que mentionné, l’expérience de l’avocat et la renommée du cabinet
            auquel il est affilié influenceront grandement le prix final à défrayer.
            C’est la raison pour laquelle il est si crucial de comparer les différents
            experts avocats avant d’en engager un et c’est exactement ce que nous
            faisons chez <strong>Soumissions Avocat</strong>!
        </p>
        <h2>
            Êtes-vous admissibles à l’aide juridique gouvernementale? Vérifiez auprès
            d’un avocat!
        </h2>
        <p>
            L’accès à la justice est un sujet en effervescence dans le monde du droit
            non pas pour des raisons élogieuses, mais bien en raison de
            l’inaccessibilité des services juridiques pour les démunis. En effet, avec
            le coût des honoraires d’avocat, les frais extrajudiciaires et les
            implications personnelles à mettre en jeu, obtenir justice lorsque nos
            moyens financiers sont faibles.
        </p>
        <p>
            Heureusement, si tel est votre situation, sachez que le gouvernement met en
            place un programme d’aide juridique offrant des services juridiques
            gratuits ou tout au moins abordables à ceux dans besoin ce programme
            comprend les deux volets suivants.
        </p>
        <p>
            <strong>Admissibilité gratuite : </strong>
            Un avocat sera mis à la disposition d’une personne nécessitant des services
            de façon tout à fait gratuite lorsque le revenu annuel de cette dernière
            sont assez faibles pour justifier une telle aide. Notamment, une personne
            seule gagnant <strong>moins de 22 750$</strong> (brut) par année pourra
            recevoir les services d’un avocat gratuitement. Les seuils d’admissibilité
            changent en fonction de l’état civil et familial de la personne concernée.
        </p>
        <p>
            <strong>Admissibilité avec contribution : </strong>
            Le volet dit contributif de l’aide juridique est offert à ceux qui
            nécessitent une aide financière, mais dont les revenus sont trop élevés
            pour se qualifier à une aide juridique. Ce volet contributif fonctionne
            d’ailleurs par l’octroi de « tranches » de 100$ aux personnes
            réquisitionnant cette aide jusqu’à concurrence de 800$. Encore une fois,
            les seuils dépendent de la situation familiale et de l’état civil des
            demandeurs d’aide.
        </p>
        <p>
            Avant de songer présenter une demande d’aide juridique, vous devriez savoir
            que ce ne sont pas tous les avocats qui traitent les dossiers de clients
            dans de telles situations pour des raisons qui leur sont propres. De plus,
            certains recours légaux ne sont pas couverts par ce programme, et ce, peu
            importe l’état de la situation financière de la personne. Vérifier auprès
            d’un avocat si votre recours se qualifie pour une telle aide!
        </p>
        <h2>
            Victime d’un accident? Les principaux critères d’une poursuite en
            responsabilité civile!
        </h2>
        <p>
            Lorsque vous êtes victimes d’un accident dû à la négligence d’une personne,
            il est normal de vouloir être compensé pour les dommages subis. Qu’ils
            soient d’ordre moraux ou physique, le préjudice subit mérite d’être réparé.
            Pour ce faire, vous aurez besoin d’un avocat spécialisé en responsabilité
            civile afin d’obtenir gain de cause. La particularité de ce domaine est que
            des régimes mis en place par l’état viennent limiter la compétence du Code
            civil du Québec en la matière. Voici donc les particularités de tels
            recours en droit québécois.
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/victime-accident-responsabilite-civile-assurances-saaq-indemnisation.jpg"
                alt="victime-accident-responsabilite-civile-assurances-saaq-indemnisation"
            />
        </p>
        <p>
            Tout commence avec les<strong> dommages </strong>subis. Rien en sert
            d’entamer un recours contre une autre personne si vous n’avez été victime
            d’aucun préjudice. Celui-ci peut être physique, matériel ou encore moral.
            Dans ce dernier cas, on réfère surtout à des propos diffamatoires ou des
            dommages qui, bien qu’affectant l’intégrité et le bien-être d’une personne,
            ne laissent pas d’empreinte physique. Il faudra également prouver
            l’existence et la gravité du dommage causé.
        </p>
        <p>
            Le droit ne prévoit pas d’assureur universel, il faudra que quelqu’un ait
            commis une<strong> faute </strong>pour que vous puissiez être indemnisé de
            quelconque façon. La faute en droit civile est difficile à définir, mais
            elle comprend un élément de négligence et de manquement à une obligation de
            diligence de la part d’une personne raisonnable. Le droit parle du standard
            du « bon père de famille » en référant au comportement d’une telle
            personne.
        </p>
        <p>
            Qu’une faute et un dommage aient été prouvé n’enlève rien au fait qu’ils
            doivent être reliés par un <strong>lien de causalité</strong> afin d’ouvrir
            la porte à un recours en responsabilité civile. Certaines présomptions
            faciliteront cette preuve dans quelques situations bien particulières, mais
            la règle générale est que le demandeur a le fardeau de prouver ce lien
            causal.
        </p>
        <p>
            Ces règles de responsabilité civile sont toujours très pertinentes
            aujourd’hui. Cependant, il y a de moins en moins de recours de la sorte qui
            se retrouvent devant les tribunaux. Pourquoi? Parce que le domaine de
            l’assurance a pris énormément de place dans les dernières décennies en
            matière d’indemnisation au point où trouver un responsable est devenu
            presque impertinent. Cela est sans parler des régimes que le gouvernent met
            en place pour indemniser les accidents en milieu de travail. Voici les
            principaux exemples.
        </p>
        <p>
            <strong>Les accidents de travail et le régime de la CNESST. </strong>
            Rien ne sert de vous prévaloir d’un recours en responsabilité civile pour
            un accident s’étant produit sur un lieu de travail, car c’est le rôle de la
            CNESST de vous indemniser. Le dossier lui sera renvoyé si vous présentez
            une demande à la cour.
        </p>
        <p>
            <strong>
                Les accidents d’automobile et le régime québécois du «        <em>no-fault</em> ».
            </strong>
            Au cours des années 1970, le Québec s’est muni d’un système de
            responsabilité sans faute en matière d’accidents d’automobile. C’est ce qui
            est communément appelé le régime « <em>no-fault</em> ». Un tel régime
            implique qu’il est désormais impossible de poursuivre une autre personne
            pour un accident d’automobile s’étant produit au Québec.
        </p>
        <p>
            <strong>
                Qui aura la tâche de vous indemniser pour votre accident d’auto?
            </strong>
            Votre assureur privé se chargera de vous dédommager pour les dommages
            matériels que vous avez subi au cours de l’accident, alors que la Société
            de l’assurance automobile du Québec (SAAQ), se chargera de vous indemniser
            pour les dommages corporels dont vous avez été atteints.
        </p>
        <h2>
            Les raisons d’engager un avocat en droit des assurances à Granby
        </h2>
        <p>
            Lorsqu’on souscrit à une police d’assurance, on pense rarement que la
            sphère juridique viendra s’y immiscer. Bien que les polices d’assurance
            soient des outils protecteurs très utiles, il se peut que votre assureur
            vous fasse la vie dure au moment de réclamer votre indemnité. Quelle qu’en
            soit la raison, lorsqu’une telle situation survient, vous n’êtes pas
            impuissants; un avocat spécialisé en droit des assurances peut vous prêter
            main-forte.
        </p>
        <p>
            <strong>
                Les avocats en droit des assurances travaillent aussi bien pour les
                assurés que pour les assureurs lorsque vient le temps d’offrir les
                services suivants!
                <br />
            </strong>
            <br />
            <strong>Analyse de police d’assurance :</strong>
            Le contrat d’assurance, que l’on qualifie plus couramment de « police
            d’assurance », en est un qui comprend plusieurs clauses parfois complexes
            et même ambiguës dans leur application. Lorsque votre assureur et vous ne
            vous entendez pas sur le sens à donner à une clause contractuelle et que
            celui-ci refuse de vous indemniser, solliciter les services d’un avocat
            peut vous éclairer sur vos chances d’obtenir gain de cause.
        </p>
        <p>
            Contestation de décision : Il arrive que l’assureur considère que votre
            préjudice ou sinistre ne soit tout simplement pas couvert par votre police
            et que la responsabilité de remplacer ou réparer les dommages vous
            revienne. Encore une fois, cette décision n’a pas à être finale et vous
            n’avez pas dit votre dernier mo; un avocat vous aidera à renverser cette
            décision.
        </p>
        <p>
            D’ailleurs, il est préférable de contacter un avocat très tôt dans le
            processus, car celui-ci peut négocier dès le premier refus une indemnité
            convenable, surtout sur les raisons de refus sont dénués de fondement
            légal.
        </p>
        <p>
            Litige contre l’assureur : Lorsque votre assureur refuse de vous indemniser
            ou qu’il vous verse un montant que vous jugez insuffisant, cela peut mener
            à un litige entre vous et votre assureur. Ce sera le rôle de votre avocat
            de vous représenter si le conflit devait dégénérer et se retrouver devant
            les tribunaux de droit commun.
        </p>
        <p>
            <strong>Règlement de différends :</strong>
            Avant d’envisager la voie litigieuse qui mènerait au procès et donc à des
            frais judiciaires considérables, votre avocat s’assurera de tenter de
            régler le règlement hors cour dans une instance privée de règlement de
            différend tel que la médiation. Cela ne vous accordera peut-être
            complètement raison, mais permettra au moins de trouver un compromis
            satisfaisant. L’arbitrage est une autre alternative intéressante à la
            justice traditionnelle!
        </p>
        <p>
            <strong>
                Que faire si votre assureur vous accuse d’avoir menti dans vos
                déclarations initiales?
            </strong>
            Si on vous accuse d’avoir fait de fausses déclarations lors de votre
            souscription à une police d’assurance ou d’avoir omis de mentionner votre
            propre aggravation du risque, il est important de contacter un avocat
            rapidement.
        </p>
        <p>
            Pour trouver les meilleurs experts en droit des assurances à Granby, quel
            que soit votre problème d’assurances, il vous faut faire confiance à
            Soumissions Avocat pour trouver le bon expert! Il ne vous reste qu’à
            remplir le formulaire pour être mis en contact avec les bons avocats.
        </p>
        <p>
            <strong> </strong>
        </p>
        <h2>
            Ce que vous devez savoir sur les assurances au Québec : obtenez les
            conseils d’un avocat!
        </h2>
        <p>
            L’assurance et le droit se côtoient de très près. D’ailleurs, les
            assurances ont remplacé bien des lois plus générales en matière de
            responsabilité civile au Québec, principalement en matière d’accidents
            d’automobile. Pour vous aider avec le côté légal de la démarche, voici les
            grandes lignes des principaux domaines d’assurance dans la province ainsi
            que les obligations des assureurs et assurés.
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/avocat-assurances-granby-conseils-768x512.jpg"
                alt="avocat assurances granby conseils"
            />
        </p>
        <p>
            <strong>L’assurance de biens : </strong>
            Une telle police d’assurance vise à protéger vos biens contre des
            évènements extérieurs qui pourraient les endommager. Ainsi, si un sinistre
            devait vous frapper, votre assureur aurait l’obligation de vous indemniser
            pour le remplacement de vos meubles.
        </p>
        <p>
            Dépendamment des clauses de votre contrat, votre assureur devra soit
            remettre ceux-ci à neuf ou à la valeur au jour du sinistre. Il va de soi
            que le montant de la prime sera adapté en conséquence. D’ailleurs, une
            franchise est à payer dans une telle police d’assurance.
        </p>
        <p>
            De plus, ce ne sont pas toutes les causes de sinistres qui vous permettront
            d’être indemnisé. Notamment, les catastrophes sont exclues d’une telle
            couverture d’assurances, qu’il s’agisse d’un tremblement de terre, d’une
            inondation, d’un glissement de terrain ou autre.
        </p>
        <p>
            Si le sinistre est dû à une faute intentionnelle de votre part, les
            assureurs refuseront également de vous indemniser. Lorsqu’on prend
            connaissance de telles exclusions, on se rend compte de l’importance de
            bien connaître nos polices d’assurances, chose avec laquelle les avocats
            peuvent vous prêter main-forte!<strong></strong>
        </p>
        <strong></strong>
        <p>
            <strong>L’assurance responsabilité civile : </strong>
            La responsabilité civile découle de l’obligation de toute personne de ne
            pas causer préjudice à autrui en suivant les usages qui s’imposent à elle
            selon ses circonstances. Plusieurs assureurs offrent une assurance pour le
            préjudice qu’un assuré pourrait causer à autrui résultant en une poursuite
            en dommages-intérêts.
        </p>
        <p>
            Ainsi, si l’assuré fait l’objet d’une poursuite, l’assureur aura
            l’obligation de défendre ce premier en fournissant un avocat. De plus,
            l’assureur en responsabilité civile, si l’assuré est reconnu coupable du
            préjudice allégué, aura l’obligation d’indemniser la victime.
        </p>
        <p>
            Cependant, la couverture offerte par l’assureur d’une assurance
            responsabilité civile connait certaines limites, notamment celle de ne pas
            couvrir la responsabilité pénale de l’assuré ni sa responsabilité
            disciplinaire. Ainsi, les gestes volontaires ou intentionnels ne sont pas
            couverts par votre police d’assurance responsabilité civile.
            <br />
            <strong>
                <br />
                L’assurance automobile :
            </strong>
            Le Québec est un exemple unique au monde en matière d’assurance automobile,
            car la province a établi un régime d’assurance sans égard à la
            responsabilité en cas d’accident. Cela implique plusieurs choses.
            Premièrement, aucun accident d’automobile, quel qu’il soit, ne peut être
            porté devant les tribunaux.
        </p>
        <p>
            Ensuite, toute personne conduisant un véhicule sur les routes du Québec est
            obligée de détenir une assurance responsabilité civile pour couvrir les
            bris matériels en cas d’accident. Les assureurs indemnisent leurs assurés
            en se fiant aux barèmes prévus d’avance dans la Convention d’indemnisation
            directe pour le règlement des sinistres automobiles. D’ailleurs, en vertu
            de cette convention, tous les assureurs indemnisent leurs propres assurés,
            ceux-ci ayant même renoncé à se poursuivre.
        </p>
        <p>
            Pour ce qui est des préjudices corporels causés par un accident
            d’automobile, les personnes accidentées ne peuvent toujours pas se
            retourner vers la personne ayant causé l’accident. La seule solution qui
            s’offre à eux est de demander à la Société de l’assurance automobile (SAAQ)
            de les indemniser, ce qu’elle fait sans problème.
        </p>
        <p>
            <strong>La notion de faute intentionnelle en matière d’assurances!</strong>
            Que ce soit en matière de responsabilité civile ou d’assurance de biens,
            les assureurs ne couvrent habituellement pas leurs assurés lorsque ceux-ci
            ont commis une faute intentionnelle.
        </p>
        <p>
            Il faut toutefois faire attention à la faute que l’on qualifiera comme tel,
            car le sens légal diffère légèrement du sens commun. Une faute
            intentionnelle en est une qui démontre une insouciance grave et une
            négligence grossière. Il s’agit d’un comportement qu’une personne
            raisonnable n’aurait pas pu pas avoir dans les mêmes circonstances.
        </p>
        <p>
            Lorsque l’assureur invoque une telle faute, la réclamation ne sera pas
            couverte par la police d’assurance et il reviendra à l’assuré de démontrer
            que la faute n’était pas intentionnelle.
        </p>
        <p>
            Si votre assureur invoque une telle faute de votre part ou qu’il refuse
            d’honorer votre police d’assurance responsabilité civile, de biens ou
            d’automobile, contactez un avocat spécialisé dans le domaine des
            assurances.
        </p>
        <h2>
            Votre poursuite peut-elle être refusée par la cour?
        </h2>
        <p>
            Les tribunaux possèdent d’importants pouvoirs relativement aux dossiers qui
            sont soumises à leurs instances. Alors que l’accès à la justice est un
            droit fondamental, la manière d’exercer certains de ces droits peut
            justifier que l’accès aux tribunaux soit refusé. Pour éviter que cela
            arrive à votre dossier, assurez-vous que vous ne rencontrez pas les
            critères suivants.
        </p>
        <p>
            <strong>Les abus de procédure :</strong>
            Pour qu’un acte constitue un abus, il doit se qualifier comme étant mal
            fondé, vexatoire, volontairement prolongé ou il doit viser à nuire à une
            autre personne. L’abus peut aussi consister à utiliser le système de façon
            excessive. Par exemple, une poursuite de plusieurs millions de dollars pour
            une simple chicane de clôture serait pour le moins abusif.
        </p>
        <p>
            <strong>La quérulence :</strong>
            Les critères de la quérulence s’apparentent à l’abus de procédure, à
            l’exception près d’être encore plus spécifique. Une personne qualifiée de
            quérulente par la cour en est une qui recherche le conflit à tout prix et
            sur des bases toujours plus frivoles les unes que les autres et qui veut se
            servir de la cour comme théâtre pour exhiber ses talents d’argumentateur.
        </p>
        <p>
            Ces individus sont parfois si persistent dans leurs poursuites que la cour
            tient maintenant un registre des plaideurs quérulents. Ceux-ci on désormais
            besoin de l’autorisation du juge en chef de la Cour supérieure pour pouvoir
            intenter un recours.
        </p>
        <p>
            <strong>Les poursuites bâillons : </strong>
            On compare les poursuites bâillons à David contre Goliath. Il s’agit en
            fait de poursuites visant à réduire au silence des parties aux moyens
            financiers plus faibles. Par exemple, des multinationales qui poursuivent
            des manifestants pour des propos supposément diffamatoires s’assure de les
            silencer, car ces derniers dépenseront tous leurs moyens à se défendre
            contre de telles allégations.
        </p>
        <p>
            Lorsqu’un juge constate l’abus de procédure, il peut décider
            <strong>
                d’interdire le recours et les actes de procédures subséquents
            </strong>
            qui arrivent au cours du procès. De plus, le fardeau de preuve de l’acte
            abusif se renverse dès que l’abus est prouvé de façon sommaire. Il ne
            suffit donc que de convaincre le juge qu’une procédure abusive a
            probablement lieu pour que la tâche de prouver l’absence d’abus ne revienne
            à l’autre partie.
        </p>
        <h2>
            Avez-vous le droit d’aller en appel de votre décision automatiquement?
        </h2>
        <p>
            Devant un jugement défavorable, il peut être tentant de tout de suite se
            précipiter vers son avocat et exiger de lui qu’il entame les procédures
            d’appel. Si vos motivations sont à la bonne place, la loi sur la procédure
            civile quant à elle prévoit des conditions bien précises pour qu’un appel
            soit justifié. Il faudra notamment faire la différence entre les deux types
            d’appel suivant.
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/cour-appel-plein-droit-autorisation-jugement-avocat-granby.jpg"
                alt="cour-appel-plein-droit-autorisation-jugement-avocat-granby"
            />
        </p>
        <p>
            <strong><em>Appel de plein droit :</em></strong>
            Un appel de plein droit en est un qui est automatiquement entendu par la
            cour d’appel en raison des caractéristiques du dossier. Voici quelques
            exemples de ce type d’appel :
        </p>
        <ul>
            <li>
                Les jugements qui tranchent la question soumise complètement.
            </li>
            <li>
                Les cas d’outrage au tribunal
            </li>
            <li>
                Les cas où le montant du litige est d’au moins 60 000$.
            </li>
            <li>
                Certains jugements rendus au cours d’un procès.
            </li>
        </ul>
        <p>
            <strong><em>Appel avec permission</em></strong>
            <em> : </em>
            Aux appels de plein droit s’opposent évidemment les appels avec permission.
            Ceux-ci n’entrent donc pas dans les catégories énumérées ci-haut. On dit
            des appels avec permission qu’ils doivent présenter un intérêt juridique
            important pour être autorisés par la Cour d’appel et présenter un principe
            nouveau en droit.
        </p>
        <p>
            <strong>
                Ce ne sont là que les grands principes entourant le droit d’appel en
                droit québécois; un avocat se fera un plaisir de vous éclairer sur vos
                chances de voir votre cause portée en appel avec succès en analysant
                les fondements de celle-ci.
            </strong>
        </p>
        <h2>
            En quoi consiste l’outrage au tribunal et quelles sont les conséquences
            rattachées?
        </h2>
        <p>
            L’outrage au tribunal est un acte qui peut se présenter de différentes
            façons. Elle peut résulter soit d’un comportement inacceptable en cour ou
            encore de la violation d’un ordre émis par un des tribunaux de la province.
            Par exemple, adopter un comportement grossier et vexatoire en cour à
            l’égard du juge ou des avocats de la partie adverse pourrait constituer un
            outrage.
        </p>
        <p>
            La violation d’un ordre telle qu’une injonction constituera elle aussi un
            outrage au tribunal. Mais quelles sont les conséquences d’un tel
            comportement offensant?
        </p>
        <ul>
            <li>
                <em>
                    Le juge peut ordonner le paiement d’une somme allant jusqu’à 10
                    000$,
                </em>
            </li>
            <li>
                <em>L’exécution de travaux communautaire,</em>
            </li>
            <li>
                <em>L’emprisonnement.</em>
            </li>
        </ul>
        <p>
            Vous avez bien lu, l’outrage au tribunal peut mener à l’emprisonnement
            lorsqu’une personne maintient son refus de collaborer et d’obéir au juge.
            La durée d’emprisonnement ne doit toutefois pas dépasser 1 an. Durant cette
            période, la personne emprisonnée pour outrage devra être convoquée
            périodiquement pour témoigner et répondre aux questions du juge.
        </p>
        <h2>
            Les questions pour votre avocat de Granby et les avantages d’engager le bon
            juriste!
        </h2>
        <p>
            Les avocats diversifient leur pratique selon leurs intérêts personnels et
            leurs aptitudes professionnelles. C’est ce qui créé une communauté
            juridique diversifiée dans le monde légal du Québec. Cela implique
            toutefois que les avocats ont tous leurs différences subtiles et c’est
            pourquoi vous devriez poser un maximum de questions pour identifier
            l’avocat qui saura vous bien servir. En posant les questions suivantes,
            vous mettes toutes les chances de votre côté.
        </p>
        <p>
            <strong>
                Quels sont les domaines de pratique dans lesquels il travaille?
            </strong>
        </p>
        <p>
            Les avocats ont généralement quelques domaines de pratique de prédilection.
            À moins de se spécialiser dans une branche extrêmement précise du droit,
            votre avocat aura plus d’une flèche à son arc. Lui en parler vous éclairera
            sur le type de services que vous pouvez vous attendre de sa part.
        </p>
        <p>
            <strong>À quoi ressemble son parcours juridique?</strong>
        </p>
        <p>
            Votre avocat est-il impliqué dans la communauté juridique? Effectue-il des
            recherches pour un institut de droit quelconque ou concentre-il ses efforts
            à la pratique. En apprendre un peu plus sur le parcours juridique de votre
            avocat peut en dire long sur ses compétences.
        </p>
        <p>
            <strong>Dans quel milieu juridique travaille-t-il?</strong>
        </p>
        <p>
            L’importance, la taille et la renommée d’un cabinet d’avocat peuvent en
            dire long sur l’avocat que vous engagez. Comme il s’agit d’un milieu très
            compétitif et prestigieux, les grands cabinets ont tendance à offrir leurs
            services d’abord aux élites et à un prix souvent élevé. Par souci de
            proportionnalité, engagez un avocat qui vous chargera un prix raisonnable
            selon la cause que vous lui soumettez.
        </p>
        <p>
            <strong>
                Qu’est-ce qui l’a convaincu à accepter votre cause et à quoi
                ressemblent vos chances de gagner?
            </strong>
        </p>
        <p>
            Absolument rien n’oblige les avocats à accepter un dossier qui leur est
            soumis. En effet, ils ont une certaine discrétion à l’égard des causes
            qu’ils entreprennent, tant que leur choix n’est pas basé sur des motifs
            discriminatoires. De ce fait, en lui demandant pourquoi il a cru bon vous
            prêter assistance, vous bâtirez une relation de confiance qui perdurera
            tout au long du dossier et même après au besoin!
        </p>
        <h2>
            Soumissions Avocat est à votre disposition dans la région de Granby pour
            trouver l’avocat qu’il vous faut!
        </h2>
        <p>
            Pourquoi vous donner des maux de têtes inutiles à jouer aux avocats en
            herbe alors que Granby regorge d’avocats compétents qui n’attendent rien
            d’autre que de prendre votre cause en main. Vous êtes maintenant convaincus
            de vos besoins en matière de représentation légale?
        </p>
        <p>
            <StaticImage placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/soumissions-trouver-comparer-avocat-region-granby-prix.jpg"
                alt="soumissions-trouver-comparer-avocat-region-granby-prix"
            />
        </p>
        <p>
            <strong>
                Laissez à Soumissions Avocat le soin de vous référer aux meilleurs
                avocats de Granby. Nous sommes les leaders du référencement juridique
                au Québec et nous seront en mesure de vous mettre en contact avec les
                avocats les plus compétents.
            </strong>
        </p>
        <p>
            <strong>
                Tout ce qu’il vous reste à faire, c’est de remplir le formulaire
                décrivant votre problème légal et nous acheminerons à 3 de nos
                partenaires avocats. Rien de plus simple, alors pourquoi attendre?
            </strong>
        </p>

    </SeoPage>
)
export default Granby
